<template>
  <div>
    <commond-form
      :request="finalForm"
      :route="`/v1/cuota/update/${cuota.id}`"
      :run="run"
      action="POST"
      @cancel="run = false"
      @success="cuotaUpdated"
      @afterError="run = false"
    >
      <template #data>
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="readonlyData.status"
              label="Estatus"
              outlined
              readonly
              hint="Solo lectura"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="readonlyData.polizaId"
              label="Poliza"
              outlined
              readonly
              hint="Solo lectura"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="form.orden"
              label="Orden"
              outlined
              :rules="[$rules.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="form.valorApagar"
              label="Valor a pagar"
              outlined
              type="number"
              :rules="[$rules.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="form.fechaPagoAcordada"
              label="Fecha de pago acordada"
              outlined
              :rules="[$rules.required]"
              type="date"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="form.fechaLimitePago"
              label="Fecha límite de pago"
              outlined
              :rules="[$rules.required]"
              type="date"
            ></v-text-field>
          </v-col>
          <!-- <v-col cols="12" md="3">
            <v-text-field
              v-model="readonlyData.fechaPagoAplicado"
              label="Fecha de pago cuota"
              outlined
              readonly
              hint="Solo lectura"
            ></v-text-field>
          </v-col> -->
        </v-row>
        <v-row v-if="cuota.status == 'Pendiente de pago'">
          <v-col cols="12" md="3">
            <v-checkbox
              v-model="extraForm.checkPagada"
              label="Cuota pagada"
            ></v-checkbox>
          </v-col>
          <v-col v-if="extraForm.checkPagada" cols="12" md="3">
            <v-text-field
              v-model="extraForm.fechaPagoCuota"
              label="Fecha pago cuota"
              outlined
              :rules="[$rules.required]"
              type="date"
            ></v-text-field>
          </v-col>
          <v-col v-if="extraForm.checkPagada" cols="12" md="6">
            <v-file-input
              v-model="extraForm.adjuntoPago"
              color="primary"
              counter
              label="Adjunto de Pago"
              multiple
              prepend-icon="mdi-paperclip"
              :show-size="1000"
              outlined
              :rules="[
                (v) => !!v.length || 'Es necesario ingresar un adjunto de pago',
              ]"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip v-if="index < 2" color="primary" dark label small>
                  {{ text }}
                </v-chip>

                <span
                  v-else-if="index === 2"
                  class="text-overline grey--text text--darken-3 mx-2"
                >
                  +{{ adjuntoPago.length - 2 }} Archivo(s)
                </span>
              </template>
            </v-file-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            style="text-align: end;"
          >
            <v-btn
              rounded
              dark
              color="#00a7e4"
              outlined
              @click="$emit('cancel')"
              style="margin-right: 20px;"
            >
              <span style="padding-right: 11%;">
                Cancelar
              </span>
            </v-btn>
            <v-btn rounded dark class="common-botton" @click="run = true">
              <span style="padding-right: 11%;">
                Guardar
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </commond-form>
  </div>
</template>

<script>
import { mainAxios } from "@/mainAxios.js";
import CommondForm from "@/components/commonComponents/CommondForm.vue";

export default {
  components: {
    CommondForm,
  },

  props: {
    cuota: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      readonlyData: {
        status: this.cuota.status,
        polizaId: this.cuota.polizaId,
        fechaPagoAplicado: this.cuota.fechaPagoAplicado,
      },
      form: {
        orden: this.cuota.orden,
        valorApagar: Number(this.cuota.valorAPagar),
        fechaPagoAcordada: this.cuota.fechaPagoAcordada,
        fechaLimitePago: this.cuota.fechaLimitePago,
      },
      extraForm: {
        checkPagada: false,
        fechaPagoCuota: null,
        adjuntoPago: [],
      },
      run: false,
    };
  },

  computed: {
    /**
     * Esta computed devuelve los datos que pueden ser mandados
     * de forma opcional, para evitar mandarlos cuando no son necesarios.
     * Así evitamos errores
     */
    extraFormData() {
      const result = {};

      if (
        this.cuota.status == "Pendiente de pago" &&
        !!this.extraForm.checkPagada
      ) {
        result.checkPagada = this.extraForm.checkPagada;
        result.fechaPagoCuota = this.extraForm.fechaPagoCuota;
      }

      return result;
    },

    finalForm() {
      return { ...this.form, ...this.extraFormData };
    },
  },

  methods: {
    cuotaUpdated() {
      if (this.extraForm.adjuntoPago.length) this.cargarArchivos();
      else this.$emit("cuotaUpdated", this.finalForm);
      window.location.reload();
    },

    cargarArchivos() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          "Content-Type": "multipart/form-data",
        },
      };

      const formData = new FormData();
      const tipo = "Documento";
      const tipoDocumento = "Otro";
      const nombre = "Adjunto Pago Cuota";
      const descripcion = `Adjunto Pago de cuota #${this.cuota.id}`;
      for (let i = 0; i < this.extraForm.adjuntoPago.length; i++) {
        let file = this.extraForm.adjuntoPago[i];
        formData.append("file", file);
      }
      formData.append("nota", descripcion);
      formData.append("tipo", tipo);
      formData.append("tipo_documento", tipoDocumento);
      formData.append("nombre", nombre);
      mainAxios
        .post(`/v1/cuota/documento/upload/${this.cuota.id}`, formData, config)
        .then(
          (result) => {
            this.$emit("cuotaUpdated", this.finalForm);
            console.log(result);
          },
          function(error) {
            console.log(error);
          }
        );
    },
  },
};
</script>
