<template>
  <div class="pt-5">
    <CommonExpansionPanel>
      <template v-slot:title-panel>
        Desglose de Comisión
      </template>
      <v-expansion-panel-content>
        <update-desglose-comision-form
          v-if="showEditForm"
          :cuota="localCuota"
          @cancel="showEditForm = false"
          @cuotaUpdated="cuotaUpdated"
        ></update-desglose-comision-form>
        <v-row v-else>
          <v-col v-if="rol == 'ADMIN' || rol == 'FINANZAS'" cols="12" class="d-flex justify-end">
            <v-btn
              rounded
              dark
              class="common-botton"
              @click="showEditForm = true"
            >
              <span style="padding-right: 11%;">
                Editar
              </span>
              <v-icon rigth>
                mdi-pencil
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" md="4">
            <span class="text-header-data ">
              Base Comisión
            </span>
            <br />
            <span class="text-data-details">
              {{ localCuota.baseComision }}
            </span>
          </v-col>
          <v-col v-if="puedeVerDesglose" cols="12" md="4">
            <span class="text-header-data ">
              Fecha de conciliación
            </span>
            <br />
            <span class="text-data-details">
              {{ formatDate(localCuota.fechaConciliacionAplicada) }}
            </span>
          </v-col>
          <v-col v-if="puedeVerDesglose" cols="12" md="4">
            <span class="text-header-data ">
                Folio de conciliacion
            </span>
            <br />
            <span class="text-data-details">
              {{ localCuota.folioDeConciliacion || "Sin Llenar" }}
            </span>
          </v-col>
          <v-col v-if="puedeVerDesglose" cols="12" md="4">
            <span class="text-header-data ">
              Comisión Pagada por la Aseguradora
            </span>
            <br />
            <span class="text-data-details">
              {{ localCuota.porcentajeComisionAseguradora }}%
            </span>
          </v-col>
          <v-col v-if="puedeVerDesglose" cols="12" md="4">
            <span class="text-header-data ">
              Monto Comisión Pagada Por la Aseguradora
            </span>
            <br />
            <span class="text-data-details">
              {{ currencyFormatt(localCuota.montoComisionPagadaAseguradora) }}
            </span>
          </v-col>
          <v-col v-if="puedeVerDesglose" cols="12" md="4">
            <span class="text-header-data ">
              Comisión Agente
            </span>
            <br />
            <span class="text-data-details">
              {{ localCuota.porcentajeComisionAgente }}%
            </span>
          </v-col>
          <v-col v-if="puedeVerDesglose || rol == 'AGENT'" cols="12" md="4">
            <span class="text-header-data ">
              Monto de Comisión Agente
            </span>
            <br />
            <span class="text-data-details">
              {{ currencyFormatt(localCuota.montoComisionAgente) }}
            </span>
          </v-col>
          <v-col v-if="puedeVerDesglose" cols="12" md="4">
            <span class="text-header-data ">
              Importe a Pagar al Agente
            </span>
            <br />
            <span class="text-data-details">
              {{ currencyFormatt(localCuota.importeAPagarAgente) }}
            </span>
          </v-col>
          <v-col v-if="puedeVerDesglose" cols="12" md="4">
            <span class="text-header-data ">
              Fecha Pagado de Comisión Agente
            </span>
            <br />
            <span class="text-data-details">
              {{ formatDate(localCuota.fechaConciliacionAplicada) }}
            </span>
          </v-col>
          <v-col v-if="puedeVerDesglose" cols="12" md="4">
            <span class="text-header-data ">
              Comisión
            </span>
            <br />
            <span class="text-data-details">
              {{ localCuota.porcentajeComisionSegurify }}%
            </span>
          </v-col>
          <v-col v-if="puedeVerDesglose" cols="12" md="4">
            <span class="text-header-data ">
              Monto de Comisión
            </span>
            <br />
            <span class="text-data-details">
              {{ currencyFormatt(localCuota.montoComisionSegurify) }}
            </span>
          </v-col>
          <v-col v-if="puedeVerDesglose" cols="12" md="4">
            <span class="text-header-data ">
              Bono
            </span>
            <br />
            <span class="text-data-details">
              {{ localCuota.porcentajeBono }}%
            </span>
          </v-col>
          <v-col v-if="puedeVerDesglose" cols="12" md="4">
            <span class="text-header-data ">
              Monto Bono
            </span>
            <br />
            <span class="text-data-details">
              {{ currencyFormatt(localCuota.montoBonoSegurify) }}
            </span>
          </v-col>
          <v-col v-if="puedeVerDesglose" cols="12" md="4">
            <span class="text-header-data ">
              Folio de Movimiento Bancario Bono
            </span>
            <br />
            <span class="text-data-details">
              {{ localCuota.folioDeConciliacionBono || "Sin Llenar" }}
            </span>
          </v-col>
          <v-col v-if="puedeVerDesglose" cols="12" md="4">
            <span class="text-header-data ">
              Monto Comisión Total
            </span>
            <br />
            <span class="text-data-details">
              {{ currencyFormatt(localCuota.montoComisiontotalSeguirfy) || 0 }}
            </span>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </CommonExpansionPanel>
  </div>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";

export default {
  components: {
    CommonExpansionPanel,
    UpdateDesgloseComisionForm: () =>
      import("./UpdateDesgloseComisionForm.vue"),
  },

  props: {
    cuota: {
      type: Object,
    },
  },

  data() {
    return {
      localCuota: this.cuota,
      showEditForm: false,
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
    };
  },

  computed: {
    isAgente() {
      return this.rol == "AGENT";
    },

    puedeVerDesglose() {
      const rolesPermitidos = ["ADMIN", "FINANZAS", "ADMINAGENT", "COBRANZA"];

      return !!rolesPermitidos.find((rol) => rol == this.rol);
    },
  },

  watch: {
    cuota(v) {
      if (v) this.localCuota = v;
    },
  },

  methods: {
    formatDate(date) {
      return date
        ? date
            .split("-")
            .reverse()
            .join("/")
        : "Sin Llenar";
    },

    currencyFormatt(val) {
      const formatoMonedaMXN = new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      });

      return formatoMonedaMXN.format(val);
    },

    cuotaUpdated(updatedData) {
      this.localCuota = {
        ...this.localCuota,
        updatedData,
      };
      this.showEditForm = false;
    },
  },
};
</script>
