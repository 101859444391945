<template>
  <div class="pt-5">
    <CommonExpansionPanel>
      <template v-slot:title-panel>
        Pago de Comisión
      </template>
      <v-expansion-panel-content>
        <v-row>
          <v-col cols="12" md="4">
            <span class="text-header-data">
              Estatus
            </span>
            <br />
            <v-btn
              rounded
              dark
              style="background-color: #f1c21b;"
              :elevation="0"
              small
            >
              <span>
                {{ getStatusName(cuota.statusComision) }}
              </span>
            </v-btn>
          </v-col>
          <v-col cols="12" md="4">
            <span class="text-header-data ">
              Agente
            </span>
            <br />
            <span class="text-data-details">
              {{ cuota.nombreAgente }}
            </span>
          </v-col>
          <v-col cols="12" md="4">
            <span class="text-header-data ">
              Importe a pagar al agente
            </span>
            <br />
            <span class="text-data-details">
              {{ currencyFormatt(cuota.importeAPagarAgente) }}
            </span>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </CommonExpansionPanel>
  </div>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";

export default {
  components: {
    CommonExpansionPanel,
  },

  props: {
    cuota: {
      type: Object,
    },
  },

  methods: {
    formatDate(date) {
      return date
        .split("-")
        .reverse()
        .join("/");
    },

    currencyFormatt(val) {
      const formatoMonedaMXN = new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      });

      return formatoMonedaMXN.format(val);
    },

    getStatusName(val){
      switch(val){
        case '1':
        case 1:
          return  'Pendiente de conciliacion por aseguradora'
        case '2':
        case 2:
          return  'Pendiente de pago a asesor'
        case 3:
        case '3':
          return 'Pagado al asesor'
        default:
          return val
      }
    }
  },
};
</script>
