<template>
  <div>
    <div>
      <v-row class="pt-5 pb-5">
        <v-col cols="12" class="d-flex">
          <span class="titulo-header mr-2">
            Detalle de Cuota
          </span>
          <span class="titulo-header">
            #{{ idCuota }}
          </span>
        </v-col>
      </v-row>
    </div>

    <div>
      <v-row style="padding-top: 60px;">
        <v-col
          cols="12"
          md="6"
          :class="
            tabReplace == 1
              ? 'text-center active-color'
              : 'text-center inactive-color'
          "
          @click="tabReplaceSelect(1)"
        >
          <span
            :class="
              tabReplace == 1
                ? 'text-header-step-active'
                : 'text-header-step-no-active'
            "
          >
            Cuota</span
          >
        </v-col>
        <v-col
          cols="12"
          md="6"
          :class="
            tabReplace == 2
              ? 'text-center active-color'
              : 'text-center inactive-color'
          "
          @click="tabReplaceSelect(2)"
        >
          <span
            :class="
              tabReplace == 2
                ? 'text-header-step-active'
                : 'text-header-step-no-active'
            "
          >
            Comisiones
          </span>
        </v-col>
      </v-row>
    </div>

    <div v-if="cuota" style="padding-top: 60px;">
      <div v-if="tabReplace == 1">
        <div>
          <cuota-info-index :cuota="cuota"></cuota-info-index>
        </div>
      </div>
      <div v-if="tabReplace == 2">
        <div>
          <comision-info-index :cuota="cuota"></comision-info-index>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CuotaInfoIndex from "./CuotaInfoIndex.vue";
import ComisionInfoIndex from "../comision/ComisionInfoIndex.vue";
import { mainAxios } from "@/mainAxios.js";

export default {
  components: {
    CuotaInfoIndex,
    ComisionInfoIndex,
  },

  data() {
    return {
      loading: false,
      requestConfig: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      },
      tabReplace: 1,
      cuota: null,
    };
  },

  computed: {
    idCuota() {
      return this.cuota ? this.cuota.id : "";
    },
  },

  mounted() {
    this.getCuota();
  },

  methods: {
    tabReplaceSelect(val) {
      this.tabReplace = val;
    },

    getCuota() {
      mainAxios
        .get(`/v1/cuota/uuid/${this.$route.params.uuid}`, this.requestConfig)
        .then((response) => {
          this.cuota = response.data;
        });
    },
  },
};
</script>
