<template>
  <div class="pt-5">
    <CommonExpansionPanel>
      <template v-slot:title-panel>
        Datos de Cuota
      </template>
      <v-expansion-panel-content>
        <update-cuota-data-form
          v-if="showEditForm"
          :cuota="localCuota"
          @cancel="showEditForm = false"
          @cuotaUpdated="cuotaUpdated"
        ></update-cuota-data-form>
        <v-row v-else>
          <v-col v-if="!isAgente" cols="12" class="d-flex justify-end">
            <v-btn
              rounded
              dark
              class="common-botton"
              @click="showEditForm = true"
            >
              <span style="padding-right: 11%;">
                Editar
              </span>
              <v-icon rigth>
                mdi-pencil
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" md="3">
            <span class="text-header-data">
              Estatus
            </span>
            <br />
            <v-btn
              rounded
              dark
              style="background-color: #f1c21b;"
              :elevation="0"
            >
              <span>
                {{ localCuota.status }}
              </span>
            </v-btn>
          </v-col>
          <v-col cols="12" md="3">
            <span class="text-header-data ">
              Póliza
            </span>
            <br />
            <span class="text-data-details">
              <a class="text-header-data" @click="toPoliza">Ir a póliza </a>
            </span>
          </v-col>
          <v-col cols="12" md="3">
            <span class="text-header-data ">
              Orden
            </span>
            <br />
            <span class="text-data-details">
              {{ localCuota.orden }}
            </span>
          </v-col>
          <v-col cols="12" md="3">
            <span class="text-header-data ">
              Valor a pagar
            </span>
            <br />
            <span class="text-data-details">
              {{ currencyFormatt(localCuota.valorAPagar) }}
            </span>
          </v-col>
          <v-col cols="12" md="3">
            <span class="text-header-data ">
              Fecha de pago acordada
            </span>
            <br />
            <span class="text-data-details">
              {{ formatDate(localCuota.fechaPagoAcordada) }}
            </span>
          </v-col>
          <v-col cols="12" md="3">
            <span class="text-header-data ">
              Fecha Límite de Pago
            </span>
            <br />
            <span class="text-data-details">
              {{ formatDate(localCuota.fechaLimitePago) }}
            </span>
          </v-col>
          <v-col cols="12" md="3">
            <span class="text-header-data ">
              Fecha Pago Cuota
            </span>
            <br />
            <span class="text-data-details">
              {{
                localCuota.fechaPagoAplicado
                  ? fechaPagoAplicadoFormatted
                  : "Sin pagar"
              }}
            </span>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </CommonExpansionPanel>
  </div>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import UpdateCuotaDataForm from "./UpdateCuotaDataForm.vue";

export default {
  components: {
    CommonExpansionPanel,
    UpdateCuotaDataForm,
  },

  props: {
    cuota: {
      type: Object,
    },
  },

  data() {
    return {
      showEditForm: false,
      localCuota: this.cuota,
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
    };
  },

  computed: {
    fechaPagoAplicadoFormatted() {
      if (this.localCuota && this.localCuota.fechaPagoAplicado)
        return this.formatDate(this.localCuota.fechaPagoAplicado.split(" ")[0]);
      return "";
    },

    isAgente() {
      return this.rol == "AGENT";
    },
  },

  methods: {
    formatDate(date) {
      return date
        .split("-")
        .reverse()
        .join("/");
    },

    currencyFormatt(val) {
      const formatoMonedaMXN = new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      });

      return formatoMonedaMXN.format(val);
    },

    cuotaUpdated(updatedData) {
      this.showEditForm = false;
      this.localCuota = {
        ...this.localCuota,
        updatedData,
        valorAPagar: updatedData.valorApagar,
      };
    },
    toPoliza(){
      this.$router.push('/detalles-emision/' + this.cuota.emisionId)
    }
  },
};
</script>
