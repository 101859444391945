<template>
  <div>
    <comision-data-card :cuota="cuota"></comision-data-card>
    <desglose-comision-card :cuota="cuota"></desglose-comision-card>
    <facturacion-comision-card :cuota="cuota"></facturacion-comision-card>
  </div>
</template>

<script>
import ComisionDataCard from "./ComisionDataCard.vue";
import DesgloseComisionCard from "./DesgloseComisionCard.vue";
import FacturacionComisionCard from "./FacturacionComisionCard.vue";

export default {
  components: {
    ComisionDataCard,
    DesgloseComisionCard,
    FacturacionComisionCard,
  },

  props: {
    cuota: Object,
  },
};
</script>
