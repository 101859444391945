<template>
  <div class="pt-5">
    <CommonExpansionPanel>
      <template v-slot:title-panel>
        Historial de modificaciones
      </template>
      <v-expansion-panel-content>
        <v-row>
          <v-col cols="12" md="4">
            <span class="text-header-data ">
              Fecha de Creación
            </span>
            <br />
            <span class="text-data-details">
              {{ formatDate(cuota.fechaCreacion) }}
            </span>
          </v-col>
          <v-col cols="12" md="4">
            <span class="text-header-data ">
              Hora
            </span>
            <br />
            <span class="text-data-details">
              {{ formatTime(cuota.fechaCreacion) }}
            </span>
          </v-col>
          <v-col cols="12" md="4">
            <span class="text-header-data ">
              Creado Por
            </span>
            <br />
            <span class="text-data-details">
              {{ cuota.creadoPor }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <span class="text-header-data ">
              Fecha de Modificación
            </span>
            <br />
            <span class="text-data-details">
              {{ formatDate(cuota.ultimaModificacion) }}
            </span>
          </v-col>
          <v-col cols="12" md="4">
            <span class="text-header-data ">
              Hora
            </span>
            <br />
            <span class="text-data-details">
              {{ formatTime(cuota.ultimaModificacion) }}
            </span>
          </v-col>
          <v-col cols="12" md="4">
            <span class="text-header-data ">
              Modificado Por
            </span>
            <br />
            <span class="text-data-details">
              {{ cuota.modificadoPor }}
            </span>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </CommonExpansionPanel>
  </div>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";

export default {
  components: {
    CommonExpansionPanel,
  },

  props: {
    cuota: {
      type: Object,
    },
  },

  methods: {
    formatDate(date) {
      return this.$timezone.formatServerTimezone(date, "DD/MM/YY");
    },

    formatTime(date) {
      return this.$timezone.formatServerTimezone(date, "HH:mm");
    },

    currencyFormatt(val) {
      const formatoMonedaMXN = new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      });

      return formatoMonedaMXN.format(val);
    },
  },
};
</script>
