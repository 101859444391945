<template>
  <div>
    <v-row v-if="cuota">
      <v-col cols="12" md="6" lg="4">
        <CommonCardDocs
          :docs="documentos"
          :routesConfigDocsCard="routesConfigNote"
          :openAll="openAll"
          :canEdit="true"
          @showAll="showAll"
        ></CommonCardDocs>
        <!-- <CommonCardEmail></CommonCardEmail> -->
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <CommonCardNotes
          :notas="notas"
          :routesConfigNoteCard="routesConfigNote"
          :openAll="openAll"
          :canEdit="true"
          @showAll="showAll"
        ></CommonCardNotes>
      </v-col>
      <v-col cols="12" md="12" lg="4">
        <CommonCardEmail
          :emails="cuota.correos"
          :emailRoutesConfig="emailRoutesConfig"
          :openAll="openAll"
          :canEdit="true"
          @showAll="showAll"
        ></CommonCardEmail>
      </v-col>
    </v-row>
    <cuota-data-card :cuota="cuota"></cuota-data-card>
    <cuota-log-card :cuota="cuota"></cuota-log-card>
  </div>
</template>

<script>
import CommonCardDocs from "@/components/commonComponents/cards/CommonCardDocs.vue";
import CommonCardEmail from "@/components/commonComponents/cards/CommonCardEmail.vue";
import CommonCardNotes from "@/components/commonComponents/cards/CommonCardNotes.vue";

import CuotaDataCard from "./CuotaDataCard.vue";
import CuotaLogCard from "./CuotaLogCard.vue";

export default {
  components: {
    CuotaDataCard,
    CuotaLogCard,
    CommonCardDocs,
    CommonCardEmail,
    CommonCardNotes,
  },

  props: {
    cuota: {
      type: Object,
    },
  },

  data() {
    return {
      openAll: false,
    };
  },

  computed: {
    documentos() {
      return this.cuota
        ? this.cuota.documentosCarga.filter(
            (d) => d.tipoRegistro == "Documento"
          )
        : [];
    },

    notas() {
      return this.cuota
        ? this.cuota.documentosCarga.filter((d) => d.tipoRegistro == "Nota")
        : [];
    },

    routesConfigNote() {
      if (!this.cuota) return {};
      return {
        action: "POST",
        route: `/v1/cuota/documento/upload/${this.cuota.id}`,
      };
    },

    emailRoutesConfig() {
      if (!this.cuota) return {};
      return {
        sendEmailRoute: "/v1/portal/cuota/correo/enviar",
        action: "POST",
        optionCorreosList: [
          {
            id: 334,
            uuid: "00464935-9c1e-4f66-aef7-0db7b1b4c110",
            correo: "admin@inter.mx",
            prospecto_id: null,
            favorito: false,
          },
        ],
        destinatario: "",
        remitente: "admin@inter.mx",
        cuota: this.cuota ? this.cuota.id : null,
      };
    },
  },

  methods: {
    showAll(val) {
      console.log({ openAll: val });
      this.openAll = val;
    },
  },
};
</script>
