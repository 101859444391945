<template>
  <div class="pt-5">
    <CommonExpansionPanel>
      <template v-slot:title-panel>
        Datos Para Factura
      </template>
      <v-expansion-panel-content>
        <v-row>
          <v-col cols="12" md="3">
            <span class="text-header-data ">
              IVA
            </span>
            <br />
            <span class="text-data-details">
              {{ currencyFormatt(cuota.iva) }}
            </span>
          </v-col>
          <v-col cols="12" md="3">
            <span class="text-header-data ">
              ISR
            </span>
            <br />
            <span class="text-data-details">
              {{ currencyFormatt(cuota.isr) }}
            </span>
          </v-col>
          <v-col cols="12" md="3">
            <span class="text-header-data ">
              Retención IVA
            </span>
            <br />
            <span class="text-data-details">
              {{ currencyFormatt(cuota.retencionIva) }}
            </span>
          </v-col>
          <v-col cols="12" md="3">
            <span class="text-header-data ">
              Folio de la Factura por Pagar
            </span>
            <br />
            <span class="text-data-details">
              {{ cuota.folioFacturaAPagar || "Sin Llenar" }}
            </span>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </CommonExpansionPanel>
  </div>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";

export default {
  components: {
    CommonExpansionPanel,
  },

  props: {
    cuota: {
      type: Object,
    },
  },

  methods: {
    formatDate(date) {
      return date
        .split("-")
        .reverse()
        .join("/");
    },

    currencyFormatt(val) {
      const formatoMonedaMXN = new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      });

      return formatoMonedaMXN.format(val);
    },
  },
};
</script>
